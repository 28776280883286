import { COOKIE_PREFIX, getCookie, setCookie } from "./utils";

document.addEventListener("DOMContentLoaded", function () {
  setClarityRole();

  storeUTMParams();
});

function setClarityRole() {
  if (!window.clarity) {
    console.error("Clarity is not loaded");
    return;
  }

  const clarityRole =
    getCookie(`${COOKIE_PREFIX}_clarity_user_role`) || "guest";

  window.clarity("set", "role", clarityRole);
}

const UTM_PARAMS = [
  "utm_id",
  "utm_name",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

function storeUTMParams() {
  // store UTM params
  const params = new URLSearchParams(window.location.search);

  UTM_PARAMS.forEach((param) => {
    if (params.has(param)) {
      setCookie(param, params.get(param), { "max-age": 86400 });
    }
  });
}
